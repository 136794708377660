import React, { useEffect, useState } from "react";
import client from "../api/client";
import { Table, Row, Col } from "antd";
import { Button } from "react-bootstrap";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { toast } from "react-toastify";

const AllOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(true);

  useEffect(() => {
    Getorders();
  }, []);

  const Getorders = async () => {
    await client({
      url: "/product/order/all",
      method: "GET",
    })
      .then((res) => {
        setOrders(res.data);
        setLoadingStatus(false);
      })
      .catch((err) => console.log(err));
  };

  const DeleteOrder = async (id) => {
    await client({
      url: `/product/order/delete/${id}`,
      method: "DELETE",
    })
      .then((res) => {
        console.log(res);
        setOrders([...orders.filter((e) => e.id !== id)]);
        toast("Order Deleted Success", {
          type: "success",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "customer_name",
      sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
    },
    {
      title: "Email",
      dataIndex: "customer_email",
      sorter: (a, b) => a.customer_email.localeCompare(b.customer_email),
    },
    {
      title: "Product",
      dataIndex: "product",
      sorter: (a, b) => a.product.localeCompare(b.product),
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: (a, b) => a.city.localeCompare(b.city),
    },
    {
      title: "State",
      dataIndex: "state",
      sorter: (a, b) => a.state.localeCompare(b.state),
    },
    {
      title: "Address1",
      dataIndex: "address1",
    },
    {
      title: "Address2",
      dataIndex: "address2",
    },
    {
      title: "Order Id",
      dataIndex: "orderId",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      // render: () => {
      //   return <DatePicker value="createdAt" />;
      // },
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
    {
      title: "Shipping Fee",
      dataIndex: "shipping_fee",
      sorter: (a, b) => a.shipping_fee - b.shipping_fee,
    },
    {
      title: "Edit",
      render: (e) => {
        return (
          <Button>
            <EditFilled />
          </Button>
        );
      },
    },
    {
      title: "Delete",
      render: (e) => {
        return (
          <Button variant="danger" onClick={() => DeleteOrder(e.id)}>
            <DeleteFilled />
          </Button>
        );
      },
    },
  ];

  return (
    <div>
      <Row className="table-container pt-5">
        <Col>
          <Table
            columns={columns}
            dataSource={orders}
            bordered={true}
            filterSearch={true}
            scroll={{ x: 1500 }}
            loading={loadingStatus}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AllOrders;
