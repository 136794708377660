import { Route, Switch } from "react-router";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import AdminPanel from "./components/AdminPanel";
import AllOrders from "./components/AllOrders";
import Login from "./components/Login";
import Logout from "./components/Logout";
import { getToken } from "./services/auth";
import NavbarComp from "./components/Navbar";
import { ToastContainer } from "react-toastify";

import "antd/dist/antd.css";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";

const ProtectetdRoute = () => {
  const token = getToken();
  if (token) {
    return (
      <div>
        <NavbarComp />
        <ToastContainer />
        <Switch>
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/orders" component={AllOrders} />
          <Route exact path="/" component={AdminPanel} />
        </Switch>
      </div>
    );
  } else {
    return <Redirect to="/login" />;
  }
};

const App = () => {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <ProtectetdRoute />
    </Switch>
  );
};

export default App;
