import { useState } from 'react';
import { useEffect } from 'react';
import { Redirect } from 'react-router';
import { isAuthenticated } from '../services/auth';


const withAuth = (Component, requireAuth = true, redirectPath = '/login') => props => {
    const [showComponent, setShowComponent] = useState(false);
    const [mounted, setMounted] = useState(false);
    useEffect(() => {
        setMounted(true);
        const isAuth = isAuthenticated();
        setShowComponent(requireAuth ? isAuth : !isAuth);
    }, []);
    if (!mounted) return false;
    return showComponent ? <Component {...props} /> : <Redirect to={redirectPath} />;
};


export default withAuth;