import withAuth from "../common/withAuth";
import ChurchesList from "./ChurchesList";

const AdminPanel = () => {
  return (
    <div>
      <ChurchesList />
    </div>
  );
};

export default withAuth(AdminPanel);
