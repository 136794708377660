import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { Formik, useFormikContext } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import ImageUploader from "react-images-upload";

const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const Field = ({ label, placeholder, name, type, control, ...rest }) => {
  const formik = useFormikContext();
  type = type || "text";
  label = label || capitalize(name);
  placeholder = placeholder || label;
  control = control || "input";

  const isValid = (name) =>
    formik.touched[name] && !formik.errors[name] && control !== "select";

  const isInvalid = (name) => formik.errors[name] && control !== "select";

  const Control = control === Form.Check ? Form.Check : Form.Control;

  return (
    <Form.Group controlId={name}>
      <Form.Label>{label}</Form.Label>
      <Control
        type={type}
        as={control}
        placeholder={placeholder}
        isValid={isValid(name)}
        isInvalid={isInvalid(name)}
        {...formik.getFieldProps(name)}
        {...rest}
      />
    </Form.Group>
  );
};

const times = Array.from({ length: 72 }, (_, index) => index + 1);

const schema = yup.object({
  name: yup.string().required(),
  description: yup.string(),
  history: yup.string(),
  status: yup.string().required().oneOf(["active", "progress", "inactive"]),
  lat: yup.number().required(),
  lng: yup.number().required(),
  price: yup.number().required(),
  time: yup.number().required().oneOf(times),
  phone: yup.string(),
  email: yup.string().email().required(),
  enableCandles: yup.boolean().required(),
});

const ChurchModal = ({ handleClose, itemToEdit, submitChurch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [horizontalChurchBackground, setHorizontalChurchBackground] =
    useState(null);
  const [verticalChurchBackground, setVerticalChurchBackground] =
    useState(null);
  const [horizontalChurchBackgroundUrl, setHorizontalChurchBackgroundUrl] =
    useState(null);
  const [verticalChurchBackgroundUrl, setVerticalChurchBackgroundUrl] =
    useState(null);
  const initialValues = {
    name: "",
    description: "",
    history: "",
    status: "active",
    lat: "",
    lng: "",
    price: "",
    time: 6,
    phone: "",
    email: "",
    enableCandles: false,
    deviceId: "",
  };

  const getFormikInitialValues = () => {
    if (itemToEdit) {
      return {
        ...itemToEdit,
        confirmAccountNumber: itemToEdit.accountNumber,
        confirmRoutingNumber: itemToEdit.routingNumber,
      };
    }
    return initialValues;
  };

  const onSubmit = async (values) => {
    const {
      confirmAccountNumber,
      confirmRoutingNumber,
      horizontalChurchBackgroundUrl,
      verticalChurchBackgroundUrl,
      ...form
    } = values;
    const submitFormValues = {
      ...form,
    };
    if (horizontalChurchBackground) {
      submitFormValues.horizontalChurchBackground = horizontalChurchBackground;
    }
    if (verticalChurchBackground) {
      submitFormValues.verticalChurchBackground = verticalChurchBackground;
    }
    setIsLoading(true);
    try {
      await submitChurch(submitFormValues);
    } catch (e) {
      if (itemToEdit) {
        alert("Failed to update church. Try again later");
      } else {
        alert("Failed to create church. Try again later");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const readObject = (file, callback) => {
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => callback(e.target.result);

      reader.readAsDataURL(file);
    }
  };

  const selectVerticalBackground = (picture) => {
    setVerticalChurchBackground(picture[0]);
    readObject(picture[0], setVerticalChurchBackgroundUrl);
  };

  const selectHorizontalBackground = (picture) => {
    setHorizontalChurchBackground(picture[0], setHorizontalChurchBackgroundUrl);
    readObject(picture[0], setHorizontalChurchBackgroundUrl);
  };

  useEffect(() => {
    if (itemToEdit) {
      console.log(itemToEdit);
      setVerticalChurchBackgroundUrl(itemToEdit.verticalChurchBackgroundUrl);
      setHorizontalChurchBackgroundUrl(
        itemToEdit.horizontalChurchBackgroundUrl
      );
    }
  }, [itemToEdit]);

  return (
    <Modal show onHide={handleClose} size="xl" keyboard={false}>
      <Formik
        initialValues={getFormikInitialValues()}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, dirty, values }) => (
          <>
            <Form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Add Church</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <Field name="name" />
                    <Field
                      type="url"
                      label="FB link"
                      placeholder="Add Your FB Link"
                      name="facebookUrl"
                    />
                    <Field
                      type="url"
                      label="Twitter link"
                      placeholder="Add Your Twitter Link"
                      name="twitterUrl"
                    />
                    <Field
                      type="url"
                      label="Website link"
                      placeholder="Add Your Website Link"
                      name="websiteUrl"
                    />
                  </Col>
                  <Col>
                    {verticalChurchBackgroundUrl && (
                      <img
                        className="w-100"
                        src={verticalChurchBackgroundUrl}
                        alt="vertical"
                      />
                    )}
                    <ImageUploader
                      withIcon={true}
                      buttonText="Choose vertical background"
                      onChange={selectVerticalBackground}
                      imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                      maxFileSize={5242880}
                    />
                    {horizontalChurchBackgroundUrl && (
                      <img
                        className="w-100"
                        src={horizontalChurchBackgroundUrl}
                        alt="horizontal"
                      />
                    )}
                    <ImageUploader
                      withIcon={true}
                      buttonText="Choose horizontal background"
                      onChange={selectHorizontalBackground}
                      imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                      maxFileSize={5242880}
                    />
                  </Col>
                </Row>
                <Field name="description" control="textarea" />
                <Field name="history" control="textarea" />
                <Field
                  type="text"
                  label="Device Id"
                  placeholder="Device Id"
                  name="deviceId"
                />
                <Row>
                  <Col xs={3}>
                    <Field type="number" name="price" />
                  </Col>
                  <Col xs={3}>
                    <Field type="number" name="time" control="select">
                      {times.map((number) => (
                        <option value={number} key={number}>
                          {number}
                        </option>
                      ))}
                    </Field>
                  </Col>
                  <Col xs={3}>
                    <Field name="status" control="select">
                      <option value="active">Active</option>
                      <option value="progress">In Progress</option>
                      <option value="inactive">Inactive</option>
                    </Field>
                  </Col>
                  <Col xs={3}>
                    <Field
                      label="Candles"
                      checked={values.enableCandles}
                      control={Form.Check}
                      type="checkbox"
                      name="enableCandles"
                    />
                  </Col>
                </Row>
                <h4>Geolocation</h4>
                <Row>
                  <Col>
                    <Field type="number" name="lat" />
                  </Col>
                  <Col>
                    <Field type="number" name="lng" />
                  </Col>
                  <Col>
                    <Field type="text" name="address" />
                  </Col>
                </Row>
                <h4>Contact Informtion</h4>
                <Row>
                  <Col>
                    <Field type="tel" name="phone" />
                  </Col>
                  <Col>
                    <Field
                      type="email"
                      name="email"
                      placeholder="name@example.com"
                    />
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="d-flex flex-wrap"
                  variant="primary"
                  type="submit"
                  disabled={
                    !dirty &&
                    !verticalChurchBackground &&
                    !horizontalChurchBackground
                  }
                >
                  <div>Submit</div>
                  {isLoading && (
                    <div className="ml-2">
                      <Spinner size="sm" animation="border" role="status" />
                    </div>
                  )}
                </Button>
              </Modal.Footer>
            </Form>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default ChurchModal;
