import { useState } from 'react';
import { useEffect } from 'react';
import { Redirect } from 'react-router';
import { logout } from '../services/auth';

const Logout = () => {
    const [mounted, setMounted] = useState(false);
    useEffect(() => {
        logout();
        setMounted(true);
    }, []);
    if (!mounted) return null;
    return <Redirect to='/login' />
};

export default Logout;