import React from "react";
import { Link } from "react-router-dom";
import { Button, Navbar } from "react-bootstrap";

function NavbarComp() {
  return (
    <div>
      <Navbar bg="dark" className="text-white">
        <Link component={Navbar.Brand} className="text-white" to="/">
          LCS admin
        </Link>
        <div className="ml-5">
          <Link to="/orders" component={Button}>
            Orders
          </Link>
        </div>
        <div className="ml-auto text-white">
          <Link to="/logout" className="text-white" component={Navbar.Brand}>
            Logout
          </Link>
        </div>
      </Navbar>
    </div>
  );
}

export default NavbarComp;
