import { getUploadConfig } from "../api/uploads";

export const uploadFileFlow = async (image) => {
  let fromdata = new FormData()
  fromdata.append('image', image)
  const {
    data: { file },
  } = await getUploadConfig(fromdata);
  // await uploadFile(file, fromdata);
  return file;
};
