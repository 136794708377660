import { useReducer } from 'react';
import { useFormik } from 'formik';
import { Redirect } from 'react-router-dom';
import * as yup from 'yup';
import { Form, Button, Container, Spinner, Alert } from 'react-bootstrap';
import { login } from '../api/auth';
import { saveToken } from '../services/auth';
import withAuth from '../common/withAuth';


const schema = yup.object({
    username: yup.string().email().required(),
    password: yup.string().required(),
});


const reducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN_REQUEST': {
            return {
                ...state,
                isLoading: true
            }
        }
        case 'LOGIN_SUCCESS': {
            return {
                ...state,
                success: true,
                isLoading: false
            }
        }
        case 'LOGIN_ERROR': {
            return {
                ...state,
                success: false,
                isLoading: false,
                message: action.payload
            }
        }
        default: {
            return state;
        }
    }
};


const Login = () => {
    const [{
        success,
        isLoading,
        message
    }, dispatch] = useReducer(reducer, {
        isLoading: false,
        success: false,
        message: null
    });
    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        onSubmit: async values => {
            dispatch({
                type: 'LOGIN_REQUEST'
            });
            try {
                const { data } = await login(values);
                dispatch({
                    type: 'LOGIN_SUCCESS'
                });
                saveToken(data.token);
            } catch (e) {
                let message;
                if (e.response.status === 401) {
                    message = 'Invalid username or password';
                }
                else {
                    message = 'Oop. Something wrong. Try again later';
                }
                dispatch({
                    type: 'LOGIN_ERROR',
                    payload: message
                });
            }
        },
        validationSchema: schema
    });


    const isValid = name => formik.touched[name] && !formik.errors[name];

    const isInvalid = name => formik.errors[name];

    if (success) {
        return <Redirect to='/' />
    }

    return (
        <Container>
            <Form onSubmit={formik.handleSubmit} className='mt-5'>
                <Form.Group controlId='username'>
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        type='email'
                        placeholder='name@example.com'
                        isValid={isValid('username')}
                        isInvalid={isInvalid('username')}
                        {...formik.getFieldProps('username')}
                    />
                </Form.Group>
                <Form.Group controlId='password'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type='password'
                        placeholder='*****'
                        isValid={isValid('password')}
                        isInvalid={isInvalid('password')}
                        {...formik.getFieldProps('password')}
                    />
                </Form.Group>
                {message && (
                    <Alert variant='danger'>
                        {message}
                    </Alert>
                )}
                <Button className='d-flex align-items-center flex-wrap' type='submit'>
                    <div>
                        Login
                    </div>
                    {isLoading && <div className=' ml-2'>
                        <Spinner size='sm' animation='border' role='status' />
                    </div>}
                </Button>
            </Form>
        </Container>
    );
};

export default withAuth(Login, false, '/');