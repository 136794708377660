import axios from 'axios';


const client = axios.create({
    // baseURL: 'http://localhost:8081'
    baseURL: 'https://api.lightsafecandle.com'
});

export const responseInterceptor = (error) => {
    if (401 === error.response.status) {
        window.location.href = '/';
    } else {
        return Promise.reject(error);
    }
}

export const requestInterceptor = (config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
};

client.interceptors.response.use((response) => response, responseInterceptor);
client.interceptors.request.use(requestInterceptor, error => Promise.reject(error));

export default client;