import axios from 'axios';
import api from './client';


export const getUploadConfig = (file) => api.post(`/upload`, file);

export const uploadFile = (url, file) => axios.put(url, file, {
    headers: {
        "Content-Type": 'image'
    }
});