import api from "./client";

export const addChurch = (form) => {
  console.log("hello");
  console.log("church add social media link: ----", form);
  return api.post("/churches", form);
};

export const updateChurch = (id, form) => api.put(`/churches/${id}`, form);

export const deleteChurch = (id) => api.delete(`/churches/${id}`);

export const getChurches = () => api.get("/churches");

export const generateChurchAccountLink = (id) =>
  api.get(`/churches/${id}/accountLink`);
