import { useReducer } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Container, Button, Alert } from "react-bootstrap";
import { Table, Row } from "antd";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import {
  addChurch,
  getChurches,
  updateChurch,
  deleteChurch,
  generateChurchAccountLink,
} from "../api/churches";
import { uploadFileFlow } from "../services/upload";
import ChurchModal from "./ChurchModal";

const initialState = {
  isLoading: false,
  isLoaded: false,
  list: [],
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "GET_CHURCHES_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "GET_CHURCHES_SUCCESS": {
      return {
        ...state,
        list: action.payload,
        isLoading: false,
        error: null,
        isLoaded: true,
      };
    }
    case "GET_CHURCHES_ERROR": {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

const mapStatus = (status) => {
  const mapper = {
    active: "Active",
    progress: "In Progress",
    inactive: "Inactive",
  };
  return mapper[status];
};

const ChurchesList = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [open, setOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(true);
  const onSubmit = async (values) => {
    console.log("suka", values);
    console.log(
      values.horizontalChurchBackground,
      values.verticalChurchBackground
    );
    if (values.horizontalChurchBackground) {
      console.log("kurwa", values.horizontalChurchBackground);
      const url = await uploadFileFlow(values.horizontalChurchBackground);
      values.horizontalChurchBackgroundUrl = url;
    }
    if (values.verticalChurchBackground) {
      console.log("kurwa2", values.verticalChurchBackground);
      const url = await uploadFileFlow(values.verticalChurchBackground);
      console.log('checking url', url);
      values.verticalChurchBackgroundUrl = url;
    }
    if (itemToEdit) {
      await updateChurch(itemToEdit.id, values);
    } else {
      await addChurch(values);
    }
    await fetchChurhces();
    setOpen(false);
  };

  useEffect(() => {
    fetchChurhces();
  }, []);

  const fetchChurhces = () => {
    dispatch({
      type: "GET_CHURCHES_REQUEST",
    });
    return getChurches()
      .then(({ data }) => {
        dispatch({
          type: "GET_CHURCHES_SUCCESS",
          payload: data,
        });
        setLoadingStatus(false);
      })
      .catch((err) => {
        dispatch({
          type: "GET_CHURCHES_ERROR",
          payload: err,
        });
        setLoadingStatus(false);
      });
  };

  const handleEdit = (item) => {

    setItemToEdit(item);
    setOpen(true);
  };

  const handleCreate = (e) => {
    setItemToEdit(null);
    setOpen(true);
  };

  const handleDelete = async (item) => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm(`Are you sure to remove ${item.name}`);
    if (confirmed) {
      await deleteChurch(item.id);
      await fetchChurhces();
    }
  };

  const handleGenerateAccountLink = async (churchId) => {
    try {
        await generateChurchAccountLink(churchId);
//       const { data } = await generateChurchAccountLink(churchId);
      // window.open(data.url, '_blank')
      alert("Account link generated successfully !!");
    } catch (err) {
      // console.log(err.response.data.message);
      alert(err.response.data.message);
    }
    // window.navigator.clipboard.writeText(data.url);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Status",
      render: (e) => {
        return mapStatus(e.status);
      },
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Stripe Account ID",
      dataIndex: "stripeAccountId",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "Lat",
      dataIndex: "lat",
    },
    {
      title: "Lng",
      dataIndex: "lng",
    },
    {
      title: "Generate Link",
      render: (e) => {
        return (
          <Button
            onClick={() => {
              handleGenerateAccountLink(e);
            }}
            className="generate-link"
          >
            Generate Link
          </Button>
        );
      },
    },
    {
      title: "Edit",
      render: (e) => {
        return (
          <Button onClick={() => handleEdit(e)}>
            <EditFilled />
          </Button>
        );
      },
    },
    {
      title: "Delete",
      render: (e) => {
        return (
          <Button onClick={() => handleDelete(e)} variant="danger">
            <DeleteFilled />
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Row className="table-container">
        <Container className="p-3">
          <Button onClick={handleCreate}>Add new church</Button>
        </Container>
        <Table
          columns={columns}
          dataSource={state.list}
          bordered={true}
          filterSearch={true}
          scroll={{ x: 1500 }}
          loading={loadingStatus}
        />
        {/* <Table responsive striped>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Status</th>
                            <th>lat, Lng</th>
                            <th>Address</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Stripe Account ID</th>
                            <th colSpan={3} />
                        </tr>
                    </thead>
                    <tbody>
                        {state.list.map(item => (
                            <tr key={item.id}>
                                <td>{item.name}</td>
                                <td>{mapStatus(item.status)}</td>
                                <td>{item.lat}, {item.lng}</td>
                                <td>{item.address}</td>
                                <td>{item.phone}</td>
                                <td>{item.email}</td>
                                <td>{item.stripeAccountId}</td>
                                <td>
                                    {!item.payoutsEnabled && (
                                        <Button
                                            size='sm'
                                            onClick={() => handleGenerateAccountLink(item.id)}
                                        >
                                            Generate Account Link
                                        </Button>
                                    )}
                                </td>
                                <td>
                                    <Button
                                        size='sm'
                                        onClick={() => handleEdit(item)
                                        }>
                                        <i className='fa fa-pen' />
                                    </Button>
                                </td>
                                <td>
                                    <Button variant='danger' size='sm' onClick={() => handleDelete(item)}>
                                        <i className='fa fa-trash' />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table> */}
        {/* {state.isLoading && (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status" />
          </div>
        )} */}
        {state.isLoaded && !state.list.length && (
          <Alert variant="danger">No churches added yet</Alert>
        )}
      </Row>
      {open && (
        <ChurchModal
          itemToEdit={itemToEdit}
          handleClose={() => setOpen(false)}
          submitChurch={onSubmit}
        />
      )}
    </>
  );
};

export default ChurchesList;
